import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Comparator from './comparator.js';
import CreditCapacity from './creditcapacity.js';

function Home(){
  return (
    <div />
  );
}

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/capacite-emprunt" element={ <CreditCapacity />} />
          <Route
            path="/comparateur/:type"
            element={<Comparator />}
          />
      </Routes>
  </Router>
  );
}

export default App;
