import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoanSlider from './loans/slider.js';
import LoansList from './loans/loanlist.js';
import {retrieveRatesFromBanks} from './loans/util.js';
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';
import './range.css';


const Comparator = () => {
  const params = useParams();
  const [amount, setAmount] = useState(7000); 
  const [nbrMounths, setNbrMounths] = useState(36); 
  const [banks, setBanks] = useState([]); // List of the different banks with their own rates
  const [loans, setLoans] = useState([]); // The final list of available bank loans in function of an amount and a number of months

  useEffect(() => {
    const fetchData = async () => {
      
      const result = await axios("../banks/" + params.type + '.json' );
      //console.log(result.data);
      setBanks(result.data);
    };
 
    fetchData();
  }, []);

  useEffect(() => {
    refreshLoans(); 
   
  }, [banks]);

  const refreshLoans = () => {
    setLoans(retrieveRatesFromBanks( banks, amount, nbrMounths));
  }

  const updateAmount = (amount) => {
       setAmount(amount); 
  }

  const updateNbrMounths = (nbrMonths) => {
       setNbrMounths(nbrMonths); 
  }

  return (
      <div>
        <h3>Offres de crédits en Belgique</h3>
        <LoanSlider 
          amount={amount}
          nbrMonths={nbrMounths}
          updateAmount={updateAmount}
          updateNbrMounths={updateNbrMounths}
          refreshLoans={refreshLoans} 
        />
        <LoansList loans={loans}/>
        <p>
        Les taux sont proposés à titre indicatif. Il est possible que nous n'ayons pas réussi à obtenir le dernier taux d'une banque. Dans tous les cas, veuillez vérifier les taux lors de votre choix final
        </p>
      </div>
  );
}


export default Comparator;
