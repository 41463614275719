import React  from 'react';
import InputRange from 'react-input-range';

const LoanSlider = (props) => {
  return (
    <form className="form-horizontal">
    <div className="row common-height clearfix">

          <div className="col-md-6 col-sm-6 bottommargin-sm">
              <div className="white-section">
                <label htmlFor="amount">Montant désiré</label>
                <div>
                  <InputRange
                    step={1000}
                    maxValue={75000}
                    minValue={1000}
                    value={props.amount}
                    onChange={value => props.updateAmount(value)}/>

                </div>
              </div>
          </div>
          <div className="col-md-6 col-sm-6 bottommargin-sm ">
              <div className="white-section">
                <label htmlFor="months">Nombre de mensualités</label>
                <div>
                  <InputRange
                    step={6}
                    maxValue={120}
                    minValue={12}
                    value={props.nbrMonths}
                    onChange={value => props.updateNbrMounths(value)}/>
                </div>

              </div>

          </div>


    </div>
    <div className="row common-height clearfix">
      <div className="col-md-offset-10 col-md-4 col-sm-4 bottommargin-sm">
        <button class="btn btn-info btn-sm panel-btn" type="button" onClick={props.refreshLoans} >CALCULER MES MENSUALITES</button>
      </div>

    </div>
    </form>
  );

}

export default LoanSlider;
