import React from 'react';

const LoansList = ({loans}) =>{
  
  return (
    <div className="panel-container">
      {loans.map((loan) => (
        <React.Fragment>
          
          {hasAffiliation(loan) ?
              <LoanItemWithLink loan={loan}/> :
              <div className="panel-card">
                <LoanItem loan={loan} />
              </div>
          }
        </React.Fragment>
          ))}
    </div>
  );
}

const LoanItemWithLink = ({loan}) => {
  return (
    <a className="panel-card" href={loan.url} target="blank">
      <LoanItem loan={loan} />
    </a>
  )
}

const LoanItem = ({loan}) => {
  return (
    <React.Fragment>
        <div className="panel panel-info">
          <div className="panel-heading">
            <h3 className="panel-title">
              {loan.bank} 
              {hasFastResponse(loan) &&
                <span className="badge badge-red">Validation rapide</span>
              }              
              {isCourtier(loan) &&
                <span className="badge badge-orange">Courtier - Conseil</span>
              }
              {hasAffiliation(loan) &&
                <span className="badge">Demande via leur site</span>
              }
              { ' ' }


              {loan.collaborativeLoan &&
                <span class="badge badge-light">Crédit collaboratif</span>
              }
              
            </h3>
          </div>
          <div className="panel-body">
            <div className="panel-body-item">
              <img className="panel-logo" src={loan.image} alt="" />
            </div>
            <div className="panel-body-item">
              {loan.taeg}
            </div>
            <div className="panel-body-item amount-per-month">
              {loan.month}
            </div>
            <div className="panel-body-item">
              {loan.interest}
            </div>
            
              <div className="panel-body-item">
                {hasAffiliation(loan) ?
                  <button className="btn btn-info btn-sm panel-btn">Consulter le site <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#fff" d="M9.17188,18.65674a1,1,0,0,1-.707-1.707L13.41406,12,8.46484,7.05029A.99989.99989,0,0,1,9.87891,5.63623L15.53516,11.293a.99964.99964,0,0,1,0,1.41406L9.87891,18.36377A.9968.9968,0,0,1,9.17188,18.65674Z"/></svg></button> :
                  <span class="badge badge-light">Taux non mis à jour</span>

                }  
              </div>
          </div>
        </div>
    </React.Fragment>
  )
}

function hasAffiliation(loan) {
  return (loan.url && loan.url !== null); 

}

function hasFastResponse(loan) {
  return loan.fastResponse
}

function isCourtier(loan) {
  return loan.courtier
}
export default LoansList;
